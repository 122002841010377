import { render, staticRenderFns } from "./Doctors.vue?vue&type=template&id=282c1a92&scoped=true&"
import script from "./Doctors.vue?vue&type=script&lang=js&"
export * from "./Doctors.vue?vue&type=script&lang=js&"
import style0 from "./Doctors.vue?vue&type=style&index=0&id=282c1a92&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "282c1a92",
  null
  
)

export default component.exports